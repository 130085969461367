/* eslint-disable react/prop-types */
import 'moment-timezone';

import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { RRule } from 'rrule';
import ReactMoment from 'react-moment';
import {
  Box,
  Grid,
  Typography,
  Link as MuiLink,
  Button,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { withLayout } from '../components/Layout';
import { items } from '../utils';
import SEO from '../components/SEO';
import EventDescription from '../components/EventDescription';
import ShareButtons from '../components/ShareButtons';

export const query = graphql`
  {
    api {
      upcomingEvents(calendar: "local", first: 10) {
        edges {
          node {
            id
            icalUid
            start {
              date
              dateTime
            }
            end {
              date
              dateTime
            }
            recurrence
            summary
            location
            description
          }
        }
      }
    }
  }
`;

const Page = ({ data, pageContext }) => {
  const upcomingEvents = items(data.api.upcomingEvents);
  const { event } = pageContext;

  const recurrence = event.recurrence
    ? RRule.fromString(event.recurrence[0]).toText()
    : null;

  let nextDate = null;

  const nextEvent = upcomingEvents.find(
    ({ icalUid }) => icalUid === event.icalUid
  );

  if (nextEvent) {
    nextDate = nextEvent.start.dateTime;
  }

  const startDate = event.start.dateTime
    ? new Date(event.start.dateTime)
    : new Date(event.start.date);
  const endDate = event.end.dateTime
    ? new Date(event.end.dateTime)
    : new Date(event.end.date);

  const allDay = event.start.date || event.end.date;
  const showTimes = !allDay;
  const showDateInTimes =
    endDate.getTime() - startDate.getTime() > 24 * 60 * 60 * 1000; // 1 day
  const showEndDate = !(
    allDay && startDate.toDateString() === endDate.toDateString()
  );

  let timeFormat = 'LT';
  if (showDateInTimes) {
    timeFormat = 'MMMM D, LT';
  }

  return (
    <>
      <SEO title={event.summary} />
      <Box mb={4}>
        <Typography variant="h1">{event.summary}</Typography>
        <Typography variant="subtitle1">
          {recurrence || (
            <>
              <ReactMoment tz="US/Mountain" format="MMMM D">
                {startDate.toISOString()}
              </ReactMoment>
              {showEndDate && (
                <>
                  {' '}
                  &mdash;{' '}
                  <ReactMoment tz="US/Mountain" format="MMMM D">
                    {endDate.toISOString()}
                  </ReactMoment>
                </>
              )}
              {', '}
              <ReactMoment tz="US/Mountain" format="YYYY">
                {endDate.toISOString()}
              </ReactMoment>
            </>
          )}
        </Typography>
      </Box>

      <Typography variant="body1" component="div">
        <Box mb={4}>
          <Grid container spacing={1}>
            {nextDate && (
              <>
                <Grid item xs={12} sm={2}>
                  <strong>Next date:</strong>
                </Grid>
                <Grid item xs={12} sm={10}>
                  <ReactMoment format="dddd, MMMM D, YYYY">
                    {nextDate}
                  </ReactMoment>
                </Grid>
              </>
            )}
            {showTimes && (
              <>
                <Grid item xs={12} sm={2}>
                  <strong>Time:</strong>{' '}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <ReactMoment tz="US/Mountain" format={timeFormat}>
                    {startDate.toISOString()}
                  </ReactMoment>{' '}
                  &mdash;{' '}
                  <ReactMoment tz="US/Mountain" format={timeFormat}>
                    {endDate.toISOString()}
                  </ReactMoment>
                </Grid>
              </>
            )}
            {event.location && (
              <>
                <Grid item xs={12} sm={2}>
                  <strong>Location:</strong>{' '}
                </Grid>
                <Grid item xs={12} sm={10}>
                  <MuiLink
                    href={`https://maps.google.com/?q=${event.location}`}
                  >
                    {event.location}
                  </MuiLink>
                </Grid>
              </>
            )}
          </Grid>
        </Box>

        <EventDescription event={event} />

        <Box mb={6}>
          <Button
            component={Link}
            to="/events/calendar"
            color="secondary"
            endIcon={<ChevronRightIcon />}
          >
            See full calendar
          </Button>
        </Box>

        <ShareButtons title={event.summary} />
      </Typography>
    </>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default withLayout(Page, { container: 'md' });
